import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";


function Tarifas() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif", marginTop: "4em"}}>
            Tarifas
            </h3>
            <br></br>
            <Row>
              <Col md="2">
                <center>
                  <button type="button" className="btn btn-outline-light">
                    <a  href={require("assets/pdf/00585-TARIFAS NOTARIALES 2025 SDN.pdf")} style={{textDecoration: "none !important"}} target="_black">
                      <img
                        alt="Imagen con referencia Tarifas Registrales"
                        width="80px"
                        src={require("assets/img/sections/pdf.jpg")}
                      ></img>
                      <h6>Tarifas Notariales 2025</h6>
                    </a>
                  </button>
                </center>
              </Col>
              <Col md="2">
                <center>
                  <button type="button" className="btn btn-outline-light">
                    <a  href={require("../../assets/pdf/00179-TARIFAS 2025  DTR SDR.pdf")} style={{textDecoration: "none !important"}} target="_black">
                      <img
                        alt="Imagen con referencia Resolucion Tarifas"
                        width="80px"
                        src={require("assets/img/sections/pdf.jpg")}
                      ></img>
                      <h6>Resolucion Tarifas DTR</h6>
                    </a>
                  </button>
                </center>
              </Col>                    
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Tarifas;
